import React, {useState} from 'react';

const Products = () => {
    const [isInfoFirstOpened, setIsInfoFirstOpened] = useState(false);
    const [isInfoSecondOpened, setIsInfoSecondOpened] = useState(false);
    const [isInfoThirdOpened, setIsInfoThirdOpened] = useState(false);


    return (
        <div className="content">
            <div className="infoPages">
                <h1>Наши продукты <span style={{color: '#E54246'}}>VR</span></h1>
                <p id="firstP" className="mt-45">Ещё в 2016 году, мы начали первые исследования и разработки в области VR/AR. Результатом было твёрдое убеждение, что VR/AR можно с успехом применять в образовании, в представительских проектах и в производстве. И в 2017 году нас пригласили на выставку ОНФ «Форум действий», где мы впервые представили свои наработки.</p>

                <div className="d-flex flex-row justify-between mt-30 mb-35">
                    <img style={{width: '21vw', height: 'auto'}} src="/img/info-products-1.jpg" alt="Photo1"/>
                    <img style={{width: '21vw', height: 'auto'}} src="/img/info-products-2.jpg" alt="Photo2"/>
                    <img style={{width: '21vw', height: 'auto'}} src="/img/info-products-3.jpg" alt="Photo3"/>
                </div>

                <p id="productsTextTab">В настоящее время, в портфеле VR/AR у компании три продукта:</p>

                <div className="dropDown d-flex flex-column">
                    <div className="d-flex flex-row justify-between">
                        <h3 className="d-flex align-start cu-p" onClick={() => setIsInfoFirstOpened(!isInfoFirstOpened)}>Речевой тренажер</h3>
                        <h3
                            onClick={() => setIsInfoFirstOpened(!isInfoFirstOpened)}
                            className={`${isInfoFirstOpened ? 'd-none' : 'd-flex align-end cu-p mr-20'}`}
                        >
                            +
                        </h3>
                        <h3
                            onClick={() => setIsInfoFirstOpened(!isInfoFirstOpened)}
                            className={`${isInfoFirstOpened ? 'd-flex align-end cu-p mr-20' : 'd-none'}`}
                        >
                            х
                        </h3>
                    </div>

                    <div className={`${isInfoFirstOpened ? 'd-flex flex-column mt-15' : 'd-none'}`}>
                        <p className="mt-10">Речевой тренажер разработан для использования на уроках английского языка на уровне основного общего образования для формирования умений общаться в условиях, максимально приближенных к реальным. Тренажер предназначен для изучающих иностранный язык на предпороговом уровне, что соответствует уровню A2, А1 общеевропейской шкалы владения иностранными языками (Common European Framework of Reference – CEFR).  Погружение в виртуальную реальность дает ощущение личного присутствия.  Пользователь может свободно передвигаться по виртуальному пространству и побывать в разных местах: прогуляться по терминалу аэропорта, пройтись по оживленным улицам города, посетить дом представителей другой культуры. Погружение в ситуацию, интерактивность, возможность манипулировать предметами создает условия для аутентичной коммуникации. При этом партнером по общению является виртуальный собеседник (аватар), выполняющий роль гида, кассира, хозяина дома и т.д.</p>

                        <div className="d-flex flex-row justify-between mt-40 mb-30">
                            <img style={{width: '29vw', height: 'auto'}} src="/img/info-products-4.jpg" alt="Photo4"/>
                            <img style={{width: '11.15vw', height: 'auto'}} src="/img/info-projects-2.jpg" alt="Photo5"/>
                            <img style={{width: '14.8vw', height: 'auto'}} src="/img/info-products-6.jpg" alt="Photo6"/>
                        </div>

                        <p className="mt-20">Сценарии симуляции основаны на типичных диалогах: знакомство, ориентация по городу, осмотр достопримечательностей (на сегодняшний день реализовано 6 сценариев). Максимальная длительность симуляций составляет 12 минут. Каждый диалог с аватаром длится 2-3 минуты и предполагает взаимодействие как с аватаром, так и с объектами виртуальной реальности.</p>
                        <p className="mt-20">Эффективность общения можно оценить благодаря разработанному алгоритму машинного анализа. Успешность выполнения задания оценивается по следующим критериям: полнота ответа, правильность грамматических структур, использование обозначенных лексических единиц, понимание смысла.</p>

                        <a className="mt-20 mb-15" href="https://cloud.mail.ru/public/jY6W/Ro4e9ruBF" target="_blank">Скачать презентацию</a>
                    </div>
                </div>

                <div className="dropDown d-flex flex-column">
                    <div className="d-flex flex-row justify-between">
                        <h3 className="d-flex align-start cu-p" onClick={() => setIsInfoSecondOpened(!isInfoSecondOpened)}>Контроль качества сборки</h3>
                        <h3
                            onClick={() => setIsInfoSecondOpened(!isInfoSecondOpened)}
                            className={`${isInfoSecondOpened ? 'd-none' : 'd-flex align-end cu-p mr-20'}`}
                        >
                            +
                        </h3>
                        <h3
                            onClick={() => setIsInfoSecondOpened(!isInfoSecondOpened)}
                            className={`${isInfoSecondOpened ? 'd-flex align-end cu-p mr-20' : 'd-none'}`}
                        >
                            х
                        </h3>
                    </div>
                    <div className={`${isInfoSecondOpened ? 'd-flex flex-column' : 'd-none'}`}>
                        <p className="mt-20">Продукт разработан для анализа качества сборки готового изделия. Для это производится сравнение реального изображения с компьютерной моделью, созданной согласно конструкторской документации.</p>

                        <div className="d-flex flex-row justify-between mt-40 mb-30">
                            <img style={{width: '27vw', height: 'auto'}} src="/img/info-products-7.jpg" alt="Photo7"/>
                            <img style={{width: '19vw', height: 'auto'}} src="/img/info-products-8.jpg" alt="Photo8"/>
                            <img style={{width: '18.1vw', height: 'auto'}} src="/img/info-products-9.jpg" alt="Photo9"/>
                        </div>

                        <div className="d-flex flex-row align-center justify-between mt-20 mb-35">
                            <p style={{marginRight: '100px'}}>Вывод результата производится, как на AR очки Epson Moverio Pro BT-2200, так и в описательный документ, сохраняющийся в БД.</p>
                            <img style={{width: '12vw', height: 'auto'}} src="/img/info-products-10.jpg" alt="Photo10"/>
                        </div>
                    </div>
                </div>

                <div className="dropDown d-flex flex-column">
                    <div className="d-flex flex-row justify-between">
                        <h3 className="d-flex align-start cu-p" onClick={() => setIsInfoThirdOpened(!isInfoThirdOpened)}>Система оценки стрессоустойчивойсти</h3>
                        <h3
                            onClick={() => setIsInfoThirdOpened(!isInfoThirdOpened)}
                            className={`${isInfoThirdOpened ? 'd-none' : 'd-flex align-end cu-p mr-20'}`}
                        >
                            +
                        </h3>
                        <h3
                            onClick={() => setIsInfoThirdOpened(!isInfoThirdOpened)}
                            className={`${isInfoThirdOpened ? 'd-flex align-end cu-p mr-20' : 'd-none'}`}
                        >
                            х
                        </h3>
                    </div>
                    <div className={`${isInfoThirdOpened ? 'd-flex flex-column' : 'd-none'}`}>
                        <p className="mt-20">Стресс, связанный с профессиональной деятельностью, представляет две основных группы проблем. Первая — это стресс-ассоциированные заболевания, которые снижают качество и продолжительность жизни, ведут к временной потере трудоспособности. Так по данным Управления здравоохранения и безопасности Великобритании, 57% всех «больничных» в 2017-2018 годах произошли по вине стресса, депрессии или беспокойства (<a href="https://www.hse.gov.uk/statistics/causdis/stress.pdf" target="_blank">подробнее здесь</a>). В РФ подобной статистики нет, но структура, по всей видимости, сходна. Вторая группа — это снижение продуктивности трудовой деятельности, допущение ошибок, вплоть до фатальных, связанных с человеческими жертвами.</p>
                        <p className="mt-20">В результате выполнения НИОКР проработан прототип аппаратно-программного комплекса, предназначенного для проведения профессионального тестирования кандидатов на должности, требующие высокой стрессоустойчивости. С помощью технологии виртуальной реальности, создаются симуляции, имитирующие рабочую деятельность испытуемого. Для создания стрессовой ситуации, в сценарий симуляции включаются элементы, вызывающие дополнительное психоэмоциональное напряжение, в частности, требующие длительной концентрации внимания, действий в условиях ограниченного времени, сенсорной перегрузки, а также ряд nowin-тестов.</p>
                        <p className="mt-20">Комплекс является альтернативой стресс-интервью. В ходе тестирования формируется отчет, содержащий подробные сведения о действиях испытуемого в виртуальной среде, показателях психоэмоционального напряжения, динамики уровня внимания, утомления, характеристики врабатываемости в разрезе типов стресс-ситуаций. HR-специалист и руководитель смогут принять обоснованное решение о приеме кандидата на работу, зная какие именно ситуации вызывают наибольшую психоэмоциональную реакцию, а в каких условиях сотрудник способен реагировать конструктивно, сохраняя спокойствие.</p>

                        <div className="d-flex flex-row justify-around mt-40 mb-35">
                            <img style={{width: '15vw', height: 'auto'}} src="/img/info-products-11.jpg" alt="Photo11"/>
                            <img style={{width: '16.2vw', height: 'auto'}} src="/img/info-products-12.jpg" alt="Photo12"/>
                        </div>
                    </div>
                </div>

                <h1>Наши продукты <span style={{color: '#E54246'}}>AI</span></h1>
                <p id="firstP" className="mt-45">Первые разработки, с применением технологий AI (Искусственного Интеллекта), были выполнение в 2018 году. Область работ: «Обработка естественного языка» (Natural Language Processing, NLP). По результатам исследований, проб и ошибок, созданное программное обеспечение было с успехом использовано при разработке речевого тренажёра.</p>
                <p className="mt-20">Вторым направлением работ с применением технологий AI, стала область «Компьютерное зрение» (Computer Vision, CV). Толчком к этому послужило создание решения «Контроль качества сборки».</p>
                <p className="mt-20">В 2022 году начались работы в очень интересной области AI «Предиктивная аналитика»  (machine learning и statistical modeling).</p>
                <p className="mt-20">С тех пор, разработки прикладного программного обеспечения в этих областях AI не прекращаются.</p>
                <p className="mt-20">В 2023 году были закончены работы по созданию ядра будущей системы анализа - «Модуль подсчета количества заданных объектов, присутствующих в кадре видеопотока». Результатом работы Модуля, являются численные данные пригодные для последующей обработки алгоритмическими методами. Это позволяет, с минимальными доработками, создавать законченные решения для самых различных задач. В настоящее время, в кадре видеопотока  распознаётся более 85 различных объектов.</p>

                <div className="d-flex flex-row justify-around mt-40 mb-35">
                    <img style={{width: '22vw', height: 'auto'}} src="/img/info-products-13.jpg" alt="Photo13"/>
                    <img style={{width: '22vw', height: 'auto'}} src="/img/info-products-14.jpg" alt="Photo14"/>
                    <img style={{width: '22vw', height: 'auto'}} src="/img/info-products-15.jpg" alt="Photo15"/>
                </div>

                <p className="mt-20">А в октябре 2023г., в рамках исполнения госконтракта №2023/ОК-п25-77 по модернизации системы «ГИС Минспорт». и используя «Модуль» как ядро, мы создали подсистему «Контроль загруженности объектов спорта».</p>

                <p className="mt-20 mb-50">Полная версия программного обеспечения «Программный комплекс для анализа видеопотока на базе искусственного интеллекта» зарегистрирована в едином реестре российских программ для электронных вычислительных машин. Реестровая запись <a href="https://reestr.digital.gov.ru/reestr/2338021/" target="_blank">№22178 от 17.04.2024</a> произведена на основании поручения Министерства цифрового развития, связи и массовых коммуникаций Российской Федерации от <a href="https://reestr.digital.gov.ru/upload/iblock/705/l7xy38k647s19e1wm7rpp8qe72c9i2pu/%D0%9F%D0%BE%D1%80%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%82%D0%BE%D1%80%D1%83%20%D0%BA%20%D0%BF%D1%80%D0%BE%D1%82%D0%BE%D0%BA%D0%BE%D0%BB%D1%83%20%E2%84%96190%D0%BF%D1%80.pdf" target="_blank">17.04.2024</a> по протоколу заседания экспертного совета от <a href="https://reestr.digital.gov.ru/upload/iblock/5ff/0um93oydmo958ieb9qpdf3htutwyeusd/%D0%9F%D1%80%D0%BE%D1%82%D0%BE%D0%BA%D0%BE%D0%BB%20%E2%84%96%20190%D0%BF%D1%80.pdf" target="_blank">04.04.2024 №190пр.</a></p>
            </div>
        </div>
    );
};

export default Products;