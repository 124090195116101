import React from 'react';
import styles from './Header.module.scss';
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";

const Index = (props) => {
    return (
        <header className="d-flex justify-between align-center">
            <div className={styles.logoBlock + " d-flex flex-column align-center mt-10"}>
                <Link style={{textDecoration: "none"}} to="/"><img className={styles.logo} src="/img/logo.png" alt="Logo"/></Link>
                <Link style={{textDecoration: "none"}} to="/"><h2 className={styles.header__brand}>Team VR</h2></Link>
            </div>
            <div className={styles.bigScreen}>
                <Link style={{textDecoration: "none"}} to="/">
                    <h3 id="main_btn" className={styles.header__button}>О компании</h3>
                </Link>
                <Link style={{textDecoration: "none"}} to="/products">
                    <h3 id={styles.centerBtn} className={styles.header__button + " products_btn"}>Продукты</h3>
                </Link>
                <Link style={{textDecoration: "none"}} to="/projects">
                    <h3 id="projects_btn" className={styles.header__button}>Проекты</h3>
                </Link>
            </div>

            <div className={styles.mobileScreen + " mr-25"}>
                <Button onClick={props.onClickMenu}>
                    <img src="/img/mobile-menu.svg" alt="Menu"/>
                </Button>
            </div>

            <div className={styles.bigScreen}>
                <Link style={{textDecoration: "none"}} to="/contacts">
                    <h3 id="projects_btn" className={styles.header__button}>Контакты</h3>
                </Link>
            </div>
        </header>
    );
};

export default Index;