import React from 'react';
import styles from './MobileMenu.module.scss';
import {Link} from "react-router-dom";

const Index = ({onCloseMenu}) => {
    return (
        <div className={styles.overlay}>
            <div className="d-flex flex-column align-center mt-10" style={{position: 'absolute', top: 0, left: '15px'}}>
                <Link onClick={onCloseMenu} style={{textDecoration: "none"}} to="/"><img className={styles.logo} src="/img/dark-logo.PNG" alt="Logo"/></Link>
                <Link onClick={onCloseMenu} style={{textDecoration: "none"}} to="/"><h2 className={styles.brand}>Team VR</h2></Link>
            </div>
            <img
                onClick={onCloseMenu}
                className={styles.closeBtn}
                src="/img/close-button.svg"
                alt="Close"
            />
            <h3 className={styles.backgroundText}>MENU</h3>
            <div className={styles.menuBlock}>
                <Link onClick={onCloseMenu} style={{textDecoration: "none"}} to="/products">
                    <h3 className={styles.menuBtn}>Продукты</h3>
                </Link>
                <Link onClick={onCloseMenu} style={{textDecoration: "none"}} to="/projects">
                    <h3 className={styles.menuBtn}>Проекты</h3>
                </Link>
                <h3 className={styles.menuBtn}>+7(391)254 53 97</h3>
            </div>
        </div>
    );
};

export default Index;