import React from 'react';


const OccupancyAnalytics = () => {
    return (
        <>
        <div className="curtain"></div>
        <div className="wrapper-dop d-flex flex-column">
            <div className="d-flex flex-row">
                <div className="redLine big-screen"/>
                <h1 className="d-flex" style={{margin: 0}}>Программный комплекс для анализа видеопотока на базе искусственного интеллекта</h1>
            </div>

            <p className="big-screen" style={{marginTop: '100px'}}>В 2022-2023 гг, командой, занимающейся направлением AI, был создан продукт «Модуль подсчета количества заданных объектов, присутствующих в кадре видеопотока». Результатом работы «Модуля» являются численные данные пригодные для последующей обработки статистическими методами. В настоящее время, в кадре видеопотока распознаётся до 80 различных объектов.</p>
            <p className="mobile-screen" style={{marginTop: '40px'}}>В 2022-2023 гг, командой, занимающейся направлением AI, был создан продукт «Модуль подсчета количества заданных объектов, присутствующих в кадре видеопотока». Результатом работы «Модуля» являются численные данные пригодные для последующей обработки статистическими методами. В настоящее время, в кадре видеопотока распознаётся до 80 различных объектов.</p>
            <p>В 2023 году, используя «Модуль», как ядро, компания выполнила адаптацию программного обеспечения и пилотный проект на тему «Учёт заполняемости спортивных сооружений». Работы выполнялись в рамках исполнения обязательств по госконтракту №2023/ОК-п25-77 от 02.10.2023 г. по модернизации ГИС Минспорта Красноярского края.</p>

            <p>В качестве источников первичной информации для анализа используются:</p>
            <p className="mt-5 ml-45">1. Видеопоток, получаемый с видеокамер существующей на спортивном объекте системы видеонаблюдения;</p>
            <p className="mt-5 ml-45">2. Архивные файлы системы видеонаблюдения.</p>

            <p>Для управления получением данных для анализа разработан интерфейс, позволяющий добавлять и редактировать информацию:</p>
            <p className="mt-5 ml-45">• О спортивных объектах;</p>
            <p className="mt-5 ml-45">•	Помещениях в спортивном объекте;</p>
            <p className="mt-5 ml-45">•	О видеокамерах в помещениях;</p>
            <p className="mt-5 ml-45">•	Секторах обзора видеокамер.</p>

            <div className="d-flex flex-row justify-around mt-30 big-screen">
                <img style={{width: '22vw', height: 'auto'}} src="/img/document-1.jpg" alt="Document 1"/>
                <img style={{width: '22vw', height: 'auto'}} src="/img/document-2.jpg" alt="Document 2"/>
                <img style={{width: '22vw', height: 'auto'}} src="/img/info-products-15.jpg" alt="Photo15"/>
            </div>
            <div className="d-flex flex-row justify-around mt-30 mobile-screen">
                <img style={{width: '40vw', height: 'auto'}} src="/img/document-1.jpg" alt="Document 1"/>
                <img style={{width: '40vw', height: 'auto'}} src="/img/document-2.jpg" alt="Document 2"/>
                <img style={{width: '40vw', height: 'auto'}} src="/img/info-products-15.jpg" alt="Photo15"/>
            </div>

            <p className="mt-50">В 2024 году, в результате развития решения, создано новое программное обеспечение, подтвержденное двумя новыми СВИДЕТЕЛЬСТВАМИ о регистрации программ для ЭВМ, № 2024610158 и № 2024662559.</p>

            <p className="mt-50">Работа программного комплекса строится по следующему алгоритму:</p>

            <div className="d-flex flex-row justify-center mt-20 big-screen">
                <img className="mt-5 align-center" style={{width: '40vw', height: 'auto'}} src="/img/block-scheme.jpg" alt="Block-scheme"/>
            </div>
            <div className="d-flex flex-row justify-center mobile-screen">
                <img className="mt-5 align-center" style={{width: '80vw', height: 'auto'}} src="/img/block-scheme.jpg" alt="Block-scheme"/>
            </div>

            <p className="mt-50">Архитектура и техническая реализация решения:</p>
            <div className="d-flex flex-row justify-center mt-20 big-screen">
                <img className="mt-5 align-center" style={{width: '70vw', height: 'auto'}} src="/img/scheme.PNG" alt="Scheme"/>
            </div>
            <div className="d-flex flex-row justify-center mobile-screen">
                <img className="mt-5 align-center" style={{width: '90vw', height: 'auto'}} src="/img/scheme.PNG" alt="Scheme"/>
            </div>

            <div className="d-flex flex-row justify-around mt-50 big-screen">
                <div className="d-flex flex-column justify-center align-center">
                    <p style={{fontSize: '14px'}}>Управление информацией о помещениях, видеокамерах</p>
                    <img style={{width: '35vw', height: 'auto'}} src="/img/screenshot-1.jpg" alt="Screenshot 1"/>
                </div>
                <div className="d-flex flex-column justify-center align-center">
                    <p style={{fontSize: '14px'}}>Календарь событий и заданий для анализа</p>
                    <img style={{width: '35vw', height: 'auto'}} src="/img/screenshot-2.jpg" alt="Screenshot 2"/>
                </div>
            </div>
            <div className="d-flex flex-row justify-between mt-50 mobile-screen">
                <div className="d-flex flex-column justify-center align-center">
                    <p style={{fontSize: '16px', marginRight: '30px'}}>Управление информацией о помещениях, видеокамерах</p>
                    <img style={{width: '45vw', height: 'auto'}} src="/img/screenshot-1.jpg" alt="Screenshot 1"/>
                </div>
                <div className="d-flex flex-column justify-center align-center">
                    <p style={{fontSize: '16px'}}>Календарь событий и заданий для анализа</p>
                    <img style={{width: '45vw', height: 'auto'}} src="/img/screenshot-2.jpg" alt="Screenshot 2"/>
                </div>
            </div>

            <p className="mt-50">Обработка online видеопотока с камер видеонаблюдения:</p>
            <div className="d-flex flex-row justify-around mt-20 big-screen">
                <img style={{width: '35vw', height: 'auto'}} src="/img/camera-1.jpg" alt="Camera 1"/>
                <img style={{width: '35vw', height: 'auto'}} src="/img/camera-2.jpg" alt="Camera 2"/>
            </div>
            <div className="d-flex flex-row justify-around mobile-screen">
                <img style={{width: '45vw', height: 'auto'}} src="/img/camera-1.jpg" alt="Camera 1"/>
                <img style={{width: '45vw', height: 'auto'}} src="/img/camera-2.jpg" alt="Camera 2"/>
            </div>

            <p className="mt-30">Результат обработки:</p>
            <table className="big-screen-table">
                <thead>
                <tr>
                    <th>Дата и время проведения анализа</th>
                    <th>Идентификатор спортивного объекта</th>
                    <th>Идентификатор помещения</th>
                    <th>Идентификатор видеокамеры</th>
                    <th>Идентификатор сектора анализа видеокамеры</th>
                    <th>Количество распознанных объектов с заданной вероятностью</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>21.10.2023 18:07</td>
                    <td>#10001</td>
                    <td>#10001#001</td>
                    <td>#10001#001#02</td>
                    <td>#10001#001#02#01</td>
                    <td>15</td>
                </tr>
                </tbody>
            </table>
            <img className="mobile-screen" style={{width: '98%', height: 'auto'}} src="/img/table-1.PNG" alt="Table 1"/>

            <p className="mt-20">Если видеокамер в помещении более чем одна, или конструкция видеокамеры позволяет менять углы обзора, а также фокусное расстояние, то для подсчёта результата используется алгоритм «бесшовного слияния секторов анализа».</p>
            <p className="mt-30">Обработка архивных видеофайлов системы видеонаблюдения:</p>
            <table>
                <thead>
                <tr>
                    <th>Идентификатор спортивного объекта</th>
                    <th>Идентификатор помещения</th>
                    <th>Идентификатор видеокамеры</th>
                    <th>Идентификатор сектора анализа видеокамеры</th>
                    <th>Анализ архива №3</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>#10001</td>
                    <td>#10001#007</td>
                    <td>#10001#007#01</td>
                    <td>#10001#007#02#01</td>
                    <td><a href="https://cloud.mail.ru/public/6ygK/TLUiNsndq" target="_blank">Результат анализа</a></td>
                </tr>
                </tbody>
            </table>

            <p className="mt-30">Результат обработки:</p>
            <table className="big-screen-table">
                <thead>
                <tr>
                    <th>Дата и время проведения анализа</th>
                    <th>Идентификатор спортивного объекта</th>
                    <th>Идентификатор помещения</th>
                    <th>Идентификатор видеокамеры</th>
                    <th>Идентификатор сектора анализа видеокамеры</th>
                    <th>Количество распознанных объектов с заданной вероятностью</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>05.05.2023 13:00</td>
                    <td>#10001</td>
                    <td>#10001#007</td>
                    <td>#10001#007#02</td>
                    <td>#10001#007#02#01</td>
                    <td>12</td>
                </tr>
                </tbody>
            </table>
            <img className="mobile-screen" style={{width: '98%', height: 'auto'}} src="/img/table-3.PNG" alt="Table 3"/>

            <p className="mt-50">В мае 2024 года мы закончили разработку собственного решения для анализа «сырых» данных - «Модуль дескриптивной аналитики и визуализации».  И теперь результаты аналитика заполняемости выглядит так:</p>
            <table>
                <thead>
                <tr>
                    <th></th>
                    <th style={{fontSize: '20px'}}>Тренажеры</th>
                    <th style={{fontSize: '20px'}}>Тренажеры 2</th>
                    <th style={{fontSize: '20px'}}>Гимнастика 2</th>
                    <th style={{fontSize: '20px'}}>Борьба</th>
                    <th style={{fontSize: '20px'}}>Борьба 2</th>
                    <th style={{fontSize: '20px'}}>Волейбол</th>
                    <th style={{fontSize: '20px'}}>Гимнастика 3</th>
                    <th style={{fontSize: '20px'}}>Гимнастика<br/> Большая</th>
                    <th style={{fontSize: '20px'}}>ИТОГО</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td style={{fontSize: '20px'}}>октябрь 2023г.</td>
                    <td style={{fontSize: '20px'}}>261</td>
                    <td style={{fontSize: '20px'}}>436</td>
                    <td style={{fontSize: '20px'}}>213</td>
                    <td style={{fontSize: '20px'}}>1 036</td>
                    <td style={{fontSize: '20px'}}>568</td>
                    <td style={{fontSize: '20px'}}>364</td>
                    <td style={{fontSize: '20px'}}>6</td>
                    <td style={{fontSize: '20px'}}>28</td>
                    <td style={{fontSize: '20px'}}>2 912</td>
                </tr>
                <tr>
                    <td style={{fontSize: '20px'}}>ноябрь 2023г.</td>
                    <td style={{fontSize: '20px'}}>368</td>
                    <td style={{fontSize: '20px'}}>1 886</td>
                    <td style={{fontSize: '20px'}}>207</td>
                    <td style={{fontSize: '20px'}}>8 231</td>
                    <td style={{fontSize: '20px'}}>743</td>
                    <td style={{fontSize: '20px'}}>623</td>
                    <td style={{fontSize: '20px'}}>89</td>
                    <td style={{fontSize: '20px'}}>4</td>
                    <td style={{fontSize: '20px'}}>12 151</td>
                </tr>
                <tr>
                    <td style={{fontSize: '20px'}}>декабрь 2023г.</td>
                    <td style={{fontSize: '20px'}}>365</td>
                    <td style={{fontSize: '20px'}}>1 762</td>
                    <td style={{fontSize: '20px'}}>327</td>
                    <td style={{fontSize: '20px'}}>7 468</td>
                    <td style={{fontSize: '20px'}}>674</td>
                    <td style={{fontSize: '20px'}}>581</td>
                    <td style={{fontSize: '20px'}}>520</td>
                    <td style={{fontSize: '20px'}}>86</td>
                    <td style={{fontSize: '20px'}}>11 783</td>
                </tr>
                <tr>
                    <td style={{fontSize: '20px'}}>январь 2024г.</td>
                    <td style={{fontSize: '20px'}}>272</td>
                    <td style={{fontSize: '20px'}}>671</td>
                    <td style={{fontSize: '20px'}}>201</td>
                    <td style={{fontSize: '20px'}}>438</td>
                    <td style={{fontSize: '20px'}}>406</td>
                    <td style={{fontSize: '20px'}}>384</td>
                    <td style={{fontSize: '20px'}}>394</td>
                    <td style={{fontSize: '20px'}}>136</td>
                    <td style={{fontSize: '20px'}}>2 902</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{fontSize: '20px'}}>ВСЕГО</td>
                    <td style={{fontSize: '20px'}}>29 748</td>
                </tr>
                </tbody>
            </table>

            <p className="mt-30">Или так: <span className="fw-bold">Ноябрь 2023г. Всего 12 151 человек</span></p>
            <table>
                <thead>
                <tr>
                    <th style={{fontSize: '20px'}}>Тренажеры<br/> ряд 1</th>
                    <th style={{fontSize: '20px'}}>Тренажеры 2<br/> ряд 2</th>
                    <th style={{fontSize: '20px'}}>Гимнастика 2<br/> ряд 3</th>
                    <th style={{fontSize: '20px'}}>Борьба<br/> ряд 4</th>
                    <th style={{fontSize: '20px'}}>Борьба 2<br/> ряд 5</th>
                    <th style={{fontSize: '20px'}}>Волейбол<br/> ряд 6</th>
                    <th style={{fontSize: '20px'}}>Гимнастика 3<br/> ряд 3</th>
                    <th style={{fontSize: '20px'}}>Гимнастика Большая<br/> ряд 8</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td style={{fontSize: '20px'}}>368</td>
                    <td style={{fontSize: '20px'}}>1 886</td>
                    <td style={{fontSize: '20px'}}>207</td>
                    <td style={{fontSize: '20px'}}>8 231</td>
                    <td style={{fontSize: '20px'}}>743</td>
                    <td style={{fontSize: '20px'}}>623</td>
                    <td style={{fontSize: '20px'}}>89</td>
                    <td style={{fontSize: '20px'}}>4</td>
                </tr>
                </tbody>
            </table>

            <img style={{width: 'auto', height: 'auto', marginTop: '30px'}} src="/img/schema-dop-info.jpg" alt="Schema1"/>
            <p className="mt-30">Или так:</p>
            <img style={{width: 'auto', height: 'auto', marginTop: '10px'}} src="/img/schema-dop-info-2.jpg" alt="Schema2"/>

            <p className="mt-30">Документы:</p>
            <table className="mb-30">
                <thead>
                <tr>
                    <th>№</th>
                    <th>Описание</th>
                    <th>Ссылка на документ</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>1</td>
                    <td>Свидетельство о государственной регистрации программы для ЭВМ "Программный комплекс для анализа загруженности помещений с использованием решений на базе искусственного интеллекта"</td>
                    <td><a href="https://drive.google.com/file/d/17lUvrKbZAeBoVhQTCuWdAHdJuItHaBgy/view?usp=sharing" target="_blank">Открыть</a></td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Договор аренды помещения на 2024 год</td>
                    <td><a href="https://drive.google.com/file/d/1R-OwzBzWNRutQj2WiTd2CRzisfrT051_/view?usp=drive_link" target="_blank">Открыть</a></td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>Инструкция по установке</td>
                    <td><a href="https://drive.google.com/file/d/1yuk0zOz3gCFsowj6P1Oq1yp_om1CN-i2/view?usp=drive_link" target="_blank">Открыть</a></td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>Обоснование отнесения созданного программного обеспечения к сфере Искусственного Интеллекта</td>
                    <td><a href="https://drive.google.com/file/d/16ICHQmV45xEZ6bDfhJtfp4zmsoQQ2qnK/view?usp=drive_link" target="_blank">Открыть</a></td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>Описание процессов</td>
                    <td><a href="https://drive.google.com/file/d/1DtJFg4cysO4bDgqMEQBPyXQvpx7qkFKX/view?usp=drive_link" target="_blank">Открыть</a></td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>Руководство пользователя</td>
                    <td><a href="https://drive.google.com/file/d/1TrJTts4BLeGo_UktDsCaK8mnUsIDcVtK/view?usp=drive_link" target="_blank">Открыть</a></td>
                </tr>
                <tr>
                    <td>7</td>
                    <td>Технические средства хранения</td>
                    <td><a href="https://drive.google.com/file/d/1AmlAySju65434272YnfnaxipY30SeqM_/view?usp=drive_link" target="_blank">Открыть</a></td>
                </tr>
                <tr>
                    <td>8</td>
                    <td>Функциональные характеристики</td>
                    <td><a href="https://drive.google.com/file/d/1inL9DxWQ6Z4wea9xekrmA6kzuEjC4ePC/view?usp=drive_link" target="_blank">Открыть</a></td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>Справка о принадлежности домена</td>
                    <td><a href="https://drive.google.com/file/d/1XVJRbgSBFKVFkufz6JMmvmioh7sPDvmB/view?usp=drive_link" target="_blank">Открыть</a></td>
                </tr>
                <tr>
                    <td>10</td>
                    <td>Акт ввода в эксплуатацию программного обеспечения</td>
                    <td><a href="https://drive.google.com/file/d/1J3C64JnmocS87NhrVn3zJwr08DwyTDfa/view?usp=drive_link" target="_blank">Открыть</a></td>
                </tr>
                <tr>
                    <td>11</td>
                    <td>Акт приемки в опытную эксплуатацию</td>
                    <td><a href="https://drive.google.com/file/d/1g2Cm0hlu8YQm7I_0lBDlQzEflkb6s1sV/view?usp=drive_link" target="_blank">Открыть</a></td>
                </tr>
                <tr>
                    <td>12</td>
                    <td>Акт сдачи-приемки выполненных работ</td>
                    <td><a href="https://drive.google.com/file/d/1qSQgAZg2iMrDRCH3tYrx0YyxvD7flVmW/view?usp=drive_link" target="_blank">Открыть</a></td>
                </tr>
                <tr>
                    <td>13</td>
                    <td>Договор №10/01ЛИИ</td>
                    <td><a href="https://drive.google.com/file/d/1H0sdpyq7wtbIN_kzREWk6X-bMKzXBemz/view?usp=drive_link" target="_blank">Открыть</a></td>
                </tr>
                <tr>
                    <td>14</td>
                    <td>Приказ о разработке и вендрении программного обеспечения</td>
                    <td><a href="https://drive.google.com/file/d/1RDbasAzgpZhEF6zRhuaVu-1RQSbz0yqu/view?usp=drive_link" target="_blank">Открыть</a></td>
                </tr>
                <tr>
                    <td>15</td>
                    <td>Свидетельство о государственной регистрации программы для ЭВМ "Модуль подсчета количества персон, присутствующих в кадре видеопотока"</td>
                    <td><a href="https://drive.google.com/file/d/1x4l_lMO-uEZoc9XV7QXJpOcXs967VXpM/view?usp=drive_link" target="_blank">Открыть</a></td>
                </tr>
                </tbody>
            </table>

            <p className="mb-50">Стоимость базовой лицензии на использование неисключительных прав на созданное программное обеспечение составляет 600 000 рублей.</p>
        </div>
        </>
    );
};

export default OccupancyAnalytics;