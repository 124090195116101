import React from 'react';
import styles from "./Foter.module.scss";

const Index = () => {
    return (
        <div className={styles.footer}>
            <p className={styles.footer_text}>© 2023, Team VR</p>
            <p style={{color: '#4D4D4D'}}>tab</p>
        </div>
    );
};

export default Index;