import {Route, Routes} from "react-router-dom";

import Header from "./components/Header";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Projects from "./pages/Projects";
import {useState} from "react";
import MobileMenu from "./components/MobileMenu";
import Footer from "./components/Footer";
import OccupancyAnalytics from "./pages/OccupancyAnalytics";
import Contacts from "./pages/Contacts";

function App() {
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

    return (
        <div className="App">
            <div className="wrapper">
                <div className="content_all">
                    {mobileMenuOpened && <MobileMenu onCloseMenu={() => setMobileMenuOpened(false)}/>}
                    <Header onClickMenu={() => setMobileMenuOpened(true)}/>

                    <Routes>
                        <Route path="/" exact element={
                            <Home/>
                        }/>
                        <Route path="/products" exact element={
                            <Products/>
                        }/>
                        <Route path="/projects" exact element={
                            <Projects/>
                        }/>
                        <Route path="/occupancyAnalytics" exact element={
                            <OccupancyAnalytics/>
                        }/>
                        <Route path="/contacts" exact element={
                            <Contacts/>
                        }/>
                    </Routes>

                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default App;
