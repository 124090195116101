import React from 'react';

const Home = () => {
    return (
        <div className="content">
            <h2 className="mtk">МТК</h2>
            <div className="mainScreen">
                <h1 className="d-flex">Разработка собственных решений в области AI и VR</h1>
                <div className="pt-30">
                    <div className="d-flex flex-row justify-between big-screen">
                        <p id="leftMainInfo" className="d-flex justify-center">Интересные задачи побуждают создавать лучшее в мире программное обеспечение</p>
                        <p id="rightMainInfo" className="d-flex justify-center">Мы открыты для обсуждения и настроены на результат</p>
                        <div className="yearInfo d-flex flex-column align-center">
                            <h3>2021</h3>
                            <div className="redLine"></div>
                            <p className="redText">Год основания</p>
                        </div>
                    </div>
                    <div className="d-flex flex-column align-start mobile-screen">
                        <p id="leftMainInfo" className="d-flex justify-center">Интересные задачи побуждают создавать лучшее в мире программное обеспечение</p>
                        <p id="rightMainInfo" className="d-flex justify-center">Мы открыты для обсуждения и настроены на результат</p>
                        <div className="yearInfo d-flex flex-column align-center">
                            <h3>2021</h3>
                            <div className="redLine"></div>
                            <p className="redText">Год основания</p>
                        </div>
                    </div>
                    <p id="mtk">17.04.2024  <a href="/OccupancyAnalytics">«Программный комплекс для анализа видеопотока на базе искусственного интеллекта»</a> зарегистрирован в едином реестре российских программ для электронных вычислительных машин. Реестровая запись <a href="https://reestr.digital.gov.ru/reestr/2338021/" target="_blank">№22178</a> от 17.04.2024</p>
                    <p id="mtk">22.05.2024 по результатам экспертизы компании присвоен статус Малая Технологическая компания (МТК)</p>
                </div>
            </div>
            <div className="infoScreen">
                <div className="numericInfo d-flex flex-row justify-between big-screen">
                    <div className="numericColumn d-flex flex-column align-center">
                        <h3 style={{color: '#2C2C2C'}}>2</h3>
                        <div className="redLine"></div>
                        <p className="redText">Глобальных</p>
                        <p className="redText" style={{marginTop: '-5px'}}>направления</p>
                        <p className="redText" style={{marginTop: '-5px'}}>деятельности</p>
                    </div>
                    <div className="numericColumn d-flex flex-column align-center">
                        <h3 style={{color: '#2C2C2C'}}>3</h3>
                        <div className="redLine"></div>
                        <p className="redText">Прикладных</p>
                        <p className="redText" style={{marginTop: '-5px'}}>проекта</p>
                        <p className="redText" style={{marginTop: '-5px'}}>в области AI</p>
                    </div>
                    <div className="numericColumn d-flex flex-column align-center">
                        <h3 style={{color: '#2C2C2C'}}>2</h3>
                        <div className="redLine"></div>
                        <p className="redText">Прикладных</p>
                        <p className="redText" style={{marginTop: '-5px'}}>проекта</p>
                        <p className="redText" style={{marginTop: '-5px'}}>в области VR</p>
                    </div>
                </div>
                <div className="numericInfo d-flex flex-column align-center mobile-screen">
                    <div className="d-flex flex-column align-center mb-20">
                        <h3 style={{color: '#2C2C2C'}}>2</h3>
                        <div className="redLine"></div>
                        <p className="redText">Глобальных</p>
                        <p className="redText" style={{marginTop: '-5px'}}>направления</p>
                        <p className="redText" style={{marginTop: '-5px'}}>деятельности</p>
                    </div>
                    <div className="d-flex flex-row justify-center">
                        <div className="d-flex flex-column align-center mr-20">
                            <h3 style={{color: '#2C2C2C'}}>3</h3>
                            <div className="redLine"></div>
                            <p className="redText">Прикладных</p>
                            <p className="redText" style={{marginTop: '-5px'}}>проекта</p>
                            <p className="redText" style={{marginTop: '-5px'}}>в области AI</p>
                        </div>
                        <div className="d-flex flex-column align-center ml-20">
                            <h3 style={{color: '#2C2C2C'}}>2</h3>
                            <div className="redLine"></div>
                            <p className="redText">Прикладных</p>
                            <p className="redText" style={{marginTop: '-5px'}}>проекта</p>
                            <p className="redText" style={{marginTop: '-5px'}}>в области VR</p>
                        </div>
                    </div>
                </div>
                <div className="lowerBlock d-flex flex-row big-screen">
                    <div className="halfBlock d-flex flex-row align-start">
                        <img className="imgLeft" src="/img/AI.PNG" alt="DesignAI"/>
                        <div className="halfBlock__textBlock d-flex flex-column">
                            <h2>AI</h2>
                            <p>Решения с использованием технологий компьютерного зрения</p>
                            <p>Решения с использованием технологий предиктивной аналитики</p>
                            <p>Системы поддержки принятия решений для различных направлений деятельности</p>
                        </div>
                    </div>
                    <div className="halfBlock d-flex flex-row align-start">
                        <img className="imgRight" src="/img/VR.PNG" alt="DesignVR"/>
                        <div className="halfBlock__textBlock d-flex flex-column">
                            <h2>VR</h2>
                            <p>Создание интерактивных VR симуляторов</p>
                            <p>Создание презентационного VR контента</p>
                        </div>
                    </div>
                </div>
                <div className="lowerBlock d-flex flex-column align-start mobile-screen">
                    <div className="halfBlock d-flex flex-row align-start">
                        <div className="halfBlock__textBlock d-flex flex-column">
                            <h2>AI</h2>
                            <p>Решения с использованием технологий компьютерного зрения</p>
                            <p>Решения с использованием технологий предиктивной аналитики</p>
                            <p>Системы поддержки принятия решений для различных направлений деятельности</p>
                        </div>
                    </div>
                    <div className="halfBlock d-flex flex-row align-start">
                        <div className="halfBlock__textBlock d-flex flex-column mb-35">
                            <h2 className="mt-30">VR</h2>
                            <p>Создание интерактивных VR симуляторов</p>
                            <p>Создание презентационного VR контента</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;