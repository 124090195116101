import React from 'react';

const Contacts = () => {
    return (
        <div className="container" style={{display: "flex", justifyItems: "center"}}>
            <table className="flex">
                <tbody>
                <tr>
                    <td><span className="fw-bold">Полное наименование</span> (в соответствии с учредительными документами)</td>
                    <td>Общество с ограниченной ответственностью «Тим Ви Ар»</td>
                </tr>
                <tr>
                    <td><span className="fw-bold">Сокращенное наименование</span> (в соответствие с учредительными документами)</td>
                    <td>ООО «Ти Ви Ар»</td>
                </tr>
                <tr>
                    <td><span className="fw-bold">ОГРН</span></td>
                    <td>1212400024803</td>
                </tr>
                <tr>
                    <td><span className="fw-bold">ИНН</span></td>
                    <td>2465342530</td>
                </tr>
                <tr>
                    <td><span className="fw-bold">КПП</span></td>
                    <td>246501001</td>
                </tr>
                <tr>
                    <td><span className="fw-bold">Юридический адрес</span> (в соответствие с учредительными документами)</td>
                    <td>660131, Красноярский край,<br/>
                        Красноярск Г., Ястынская УЛ., дом № 9, помещение 68
                    </td>
                </tr>
                <tr>
                    <td><span className="fw-bold">Фактический адрес</span></td>
                    <td>660131, Красноярский край,<br/>
                        Красноярск Г., Ястынская УЛ., дом № 9, помещение 68
                    </td>
                </tr>
                <tr>
                    <td><span className="fw-bold">Почтовый адрес</span></td>
                    <td>660131, Красноярский край,<br/>
                        Красноярск Г., Ястынская УЛ., дом № 9, помещение 68
                    </td>
                </tr>
                <tr>
                    <td><span className="fw-bold">Банк клиента</span></td>
                    <td>ФИЛИАЛ "ЦЕНТРАЛЬНЫЙ" БАНКА ВТБ (ПАО)</td>
                </tr>
                <tr>
                    <td><span className="fw-bold">БИК банка</span></td>
                    <td>044525411</td>
                </tr>
                <tr>
                    <td><span className="fw-bold">Расчетный счет</span></td>
                    <td>40702810811740003248</td>
                </tr>
                <tr>
                    <td><span className="fw-bold">Корреспондентский счет</span></td>
                    <td>30101810145250000411</td>
                </tr>
                <tr>
                    <td><span className="fw-bold">Телефон</span></td>
                    <td>+7(391)254 53 97</td>
                </tr>
                <tr>
                    <td><span className="fw-bold">ОКВЭД</span></td>
                    <td>62.01  62.02  72.19  63.11  46.51</td>
                </tr>
                <tr>
                    <td><span className="fw-bold">Е-mail</span></td>
                    <td>info@teamvr.ru</td>
                </tr>
                <tr>
                    <td><span className="fw-bold">Генеральный директор<br/>
                        ООО «Ти Ви Ар" (действует на основании Устава)</span>
                    </td>
                    <td>Виктор Семёнович Будников</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Contacts;